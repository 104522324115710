import React, { ReactNode } from 'react'
import clsx from 'clsx'

interface Props {
  size?: '20' | '24'
  children: ReactNode
  className?: string
  fillNone?: boolean
}

export type IconProps = Pick<Props, 'size' | 'className' | 'fillNone'>

const IconBase = ({ size = '20', children, className = undefined, fillNone = false }: Props) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill={clsx(fillNone ? 'none' : 'currentColor')}
    width={size}
    height={size}
    className={className}
  >
    {children}
  </svg>
)

export default IconBase
