export const API_ROUTES = {
  LOGIN: '/api/auth/login',
  LOGOUT: '/api/auth/logout',
}

export const APP_ROUTES = {
  HOME: '/',
  LOGIN: '/auth/login',
  SUPPLIES: '/supplies',
  SUPPLIES_NEW: '/supplies/new',
  DEMAND_REQUESTS: '/demand-requests',
  DEMAND_REQUESTS_NEW: '/demand-requests/new',
  CERTIFICATES: '/certificates',
  CHANGE_PASSWORD: '/auth/change-password',
  RESET_PASSWORD: '/auth/reset-password',
}

export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL
