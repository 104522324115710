import React, { ReactNode } from 'react'
import { Popover } from '@headlessui/react'

export type Props = {
  children: ReactNode
}

const PopoverButton: React.FC<Props> = ({ children }) => (
  <Popover.Button className="focus:outline-none">{children}</Popover.Button>
)

export default PopoverButton
