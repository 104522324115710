import React from 'react'
import clsx from 'clsx'

export type Props = {
  className?: string
  children: React.ReactNode
  size?: 'sm' | 'md' | 'lg' | 'xl'
}

const Container: React.FC<Props> = ({ children, className = undefined, size = 'lg' }) => (
  <div
    className={clsx(
      className,
      'mx-auto px-4 md:px-10 xl:px-20',
      size === 'sm' && 'max-w-[740px]',
      size === 'md' && 'max-w-[920px]',
      size === 'lg' && 'max-w-[1340px]',
      size === 'xl' && 'max-w-[1600px]'
    )}
  >
    {children}
  </div>
)

export default Container
