import React, { ReactNode, Fragment } from 'react'
import clsx from 'clsx'
import { Popover, Transition } from '@headlessui/react'

export type Props = {
  children: ReactNode
  edge?: 'right' | 'left'
  size?: 'md'
  variant?: 'default'
  className?: string
}

const PopoverPanel: React.FC<Props> = ({
  children,
  edge = 'left',
  size = 'md',
  variant = 'default',
  className = undefined,
}) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-200"
    enterFrom="opacity-0 translate-y-1"
    enterTo="opacity-100 translate-y-0"
    leave="transition ease-in duration-150"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 translate-y-1"
  >
    <Popover.Panel
      className={clsx(
        'absolute top-full z-10 mt-2',
        variant === 'default' && 'rounded-lg border border-stone-200 bg-white p-4 shadow',
        edge === 'right' && '-right-1 origin-top-right',
        edge === 'left' && '-left-1 origin-top-left',
        size === 'md' && 'min-w-[13rem]',
        className
      )}
    >
      {children}
    </Popover.Panel>
  </Transition>
)

export default PopoverPanel
