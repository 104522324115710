import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconShieldCheckmark = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path d="m10.008 1.6367a0.62506 0.62506 0 0 0-0.4375 0.17188c-1.7687 1.6796-4.1236 2.6004-6.5625 2.5664a0.62506 0.62506 0 0 0-0.60352 0.42969c-0.35167 1.0714-0.5302 2.1927-0.5293 3.3203 0 4.945 3.384 9.1103 7.9688 10.291a0.62506 0.62506 0 0 0 0.3125 0c4.5849-1.1799 7.9688-5.3461 7.9688-10.291 0-1.1585-0.18564-2.275-0.5293-3.3203a0.62506 0.62506 0 0 0-0.59375-0.42969h-0.12695c-2.5006 0-4.7651-0.9745-6.4453-2.5664a0.62506 0.62506 0 0 0-0.42188-0.17188zm-0.011718 1.3535c1.758 1.5134 3.9935 2.4553 6.4688 2.5547 0.23726 0.82436 0.41016 1.6776 0.41016 2.5801 0 4.3142-2.9187 7.9145-6.875 9.0117-3.9565-1.098-6.875-4.6974-6.875-9.0117a0.62506 0.62506 0 0 0 0-0.0019531c-7.07e-4 -0.88269 0.16937-1.7507 0.41406-2.5977 2.3874-0.094512 4.6449-0.97666 6.457-2.5352zm2.4922 4.5098a0.625 0.625 0 0 0-0.49609 0.26172l-2.6973 3.7754-1.3535-1.3535a0.625 0.625 0 0 0-0.88281 0 0.625 0.625 0 0 0 0 0.88281l1.875 1.875a0.62506 0.62506 0 0 0 0.94922-0.078125l3.125-4.375a0.625 0.625 0 0 0-0.14453-0.87109 0.625 0.625 0 0 0-0.375-0.11719z" />
  </IconBase>
)

export default IconShieldCheckmark
