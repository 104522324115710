import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconArrowUp = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.54166C10.3452 3.54166 10.625 3.82148 10.625 4.16666L10.625 14.3244L14.5581 10.3914C14.8021 10.1473 15.1979 10.1473 15.4419 10.3914C15.686 10.6355 15.686 11.0312 15.4419 11.2753L10.4419 16.2753C10.3247 16.3925 10.1658 16.4583 10 16.4583C9.83424 16.4583 9.67527 16.3925 9.55806 16.2753L4.55806 11.2753C4.31398 11.0312 4.31398 10.6355 4.55806 10.3914C4.80214 10.1473 5.19786 10.1473 5.44194 10.3914L9.375 14.3244L9.375 4.16666C9.375 3.82148 9.65482 3.54166 10 3.54166Z"
    />
  </IconBase>
)

export default IconArrowUp
