import React, { ButtonHTMLAttributes, AnchorHTMLAttributes, useMemo } from 'react'
import clsx from 'clsx'

type CommonProps = {
  variant?: 'primary' | 'primary-border'
  size?: 'md'
  loading?: boolean
}

export type ButtonProps = CommonProps & ButtonHTMLAttributes<HTMLButtonElement>
export type AnchorProps = CommonProps & AnchorHTMLAttributes<HTMLAnchorElement>

const isAnchor = (props: ButtonProps | AnchorProps): props is AnchorProps =>
  typeof (props as AnchorProps).href === 'string'

export type Props = ButtonProps | AnchorProps

// eslint-disable-next-line react/require-default-props
const Button: React.FC<Props> = React.forwardRef<HTMLAnchorElement & HTMLButtonElement, Props>(
  (props, ref) => {
    const {
      variant = 'primary',
      size = 'md',
      children = null,
      className = '',
      loading = false,
      ...otherProps
    } = props

    // eslint-disable-next-line react/destructuring-assignment
    const disabled = isAnchor(props) ? false : props.disabled

    const computedClassName = clsx(
      className,
      'inline-flex relative items-center text-center space-x-1 justify-center leading-none border rounded-lg transition-all duration-300 ease-in-out transform',
      !disabled && 'focus-element hover:-translate-y-0.5',
      variant === 'primary' && [
        'border-primary bg-primary text-white',
        !disabled && 'hover:shadow-1',
      ],
      variant === 'primary-border' && [
        'border-primary bg-white text-primary',
        !disabled && 'hover:bg-primary-100',
      ],
      disabled && 'border-stone-200 bg-stone-100 text-opacity-30 cursor-not-allowed',
      size === 'md' && 'h-9 px-4 text-sm font-medium'
    )

    const loadingSpinner = useMemo(
      () =>
        loading ? (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center rounded-lg bg-white bg-opacity-60">
            <div className="h-5 w-5 animate-spin rounded-full border-l-2 border-stone" />
          </div>
        ) : null,
      [loading]
    )

    const content = useMemo(
      () => (
        <>
          {loadingSpinner}
          {children}
        </>
      ),
      [children, loadingSpinner]
    )

    if (isAnchor(props)) {
      return (
        <a ref={ref} className={computedClassName} {...(otherProps as AnchorProps)}>
          {content}
        </a>
      )
    }
    return (
      // eslint-disable-next-line react/button-has-type
      <button ref={ref} className={computedClassName} {...(otherProps as ButtonProps)}>
        {content}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
