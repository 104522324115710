import React from 'react'

import IconBase, { IconProps } from './IconBase'

const IconArrowDown = ({ size, className }: IconProps) => (
  <IconBase size={size} className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.55806 3.72471C9.80214 3.48064 10.1979 3.48064 10.4419 3.72471L15.4419 8.72471C15.686 8.96879 15.686 9.36452 15.4419 9.6086C15.1979 9.85268 14.8021 9.85268 14.5581 9.6086L10.625 5.67554L10.625 15.8333C10.625 16.1785 10.3452 16.4583 10 16.4583C9.65482 16.4583 9.375 16.1785 9.375 15.8333L9.375 5.67554L5.44194 9.6086C5.19786 9.85268 4.80214 9.85268 4.55806 9.6086C4.31398 9.36452 4.31398 8.96879 4.55806 8.72472L9.55806 3.72471Z"
    />
  </IconBase>
)

export default IconArrowDown
