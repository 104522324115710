import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useUser } from '@supabase/auth-helpers-react'
import { useRouter } from 'next/router'
import { APP_ROUTES } from 'utils/constants'
import Header from './AppShell/Header'

interface Props {
  children: React.ReactNode
  hideHeader?: boolean
  requiresAuth?: boolean
}

const Layout: React.FC<Props> = ({ children, hideHeader = false, requiresAuth = false }) => {
  const { user, isLoading } = useUser()

  const { push } = useRouter()

  useEffect(() => {
    if (!requiresAuth) return

    if (!user && !isLoading) {
      push(APP_ROUTES.LOGIN)
    }
  }, [user, isLoading, requiresAuth, push])

  return (
    <>
      {!hideHeader && <Header />}

      <main className="flex-1 bg-stone-50 py-12">{children}</main>
    </>
  )
}

export default Layout
